<template>
  <div class="animated fadeIn">
    <!-- <LoadingWidget v-if="isLoading" :status="initStatus" @init="init" /> -->
    <b-card>
      <template v-slot:header>
        <h5>Laporan Invoice {{ role }}</h5>
      </template>
      <!-- NEW FILTER -->
      <b-form @submit.prevent="getLaporanPembelian">
        <div class="row mb-3">
          <div class="col-md-4" v-if="role == 'Super Admin'">
            <b-form-group label="Pilih Cabang">
              <multiselect multiple v-model="cabang" :options="cabangLists" :internal-search="true" :allow-empty="true"
                deselect-label="" :close-on-select="false" select-label="" label="name" track-by="id"
                placeholder="pilih cabang" />
            </b-form-group>
          </div>
          <!-- <div class="col-md-4">
            <b-form-group label="Pilih Supplier">
              <multiselect v-model="supplier" :options="suppliers" :internal-search="true" :allow-empty="false"
                deselect-label="" @select="handleSelectSupplier" select-label="" label="nama" track-by="id"
                placeholder="pilih supplier" />
            </b-form-group>
          </div> -->
          <div class="col-md-4">
            <b-form-group label="Filter tanggal">
              <date-range-picker @select="handleSelectDate" class="w-100" v-model="dateRange" ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true" :showDropdowns="true"
                :autoApply="true">
              </date-range-picker>
            </b-form-group>
          </div>
          <div class="col d-flex justify-content-end justify-content-md-start align-items-center">
            <b-button :disabled="isLoading" type="submit" class="mt-2 mr-2" variant="pgiBtn">
              Load
            </b-button>
            <b-button class="mt-2" variant="pgiBtn" name="resetTableBtn" :disabled="isLoading" @click="onReset()">
              Reset
            </b-button>
          </div>
        </div>
      </b-form>
      <b-table bordered hover show-empty :busy="isLoading" :items="pembelian" :fields="fields" :perPage="perPage"
        responsive class="mb-3">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading data...</strong>
          </div>
        </template>
        <template v-slot:empty> Tidak ada data jenis barang. </template>
        <template v-slot:cell(totalPembelian)="row">
          <div class="d-flex align-items-center justify-content-between">
            <div>Rp.</div>
            <div class="text-end">{{ row.item.totalPembelian }}</div>
          </div>
        </template>
        <template v-slot:cell(status)="row">
          <b-badge variant="secondary" v-if="row.item.status == 0">Belum Proses</b-badge>
          <b-badge variant="primary" v-if="row.item.status == 1">Sudah Diproses</b-badge>
          <b-badge variant="info" class="text-white" v-if="row.item.status == 2">Sudah Dibayar</b-badge>
          <b-badge variant="success" v-if="row.item.status == 3">Diterima</b-badge>
          <b-badge variant="danger" v-if="row.item.status == 4">Dibatalkan</b-badge>
          <b-badge variant="danger" v-if="row.item.status == 5">Barang Selisih</b-badge>
          <b-badge variant="warning" v-if="row.item.status == 6">Permintaan Void</b-badge>
        </template>
        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-button size="sm" class="mr-3 table-button view" @click="handleOpenDetail(row.item)" v-b-tooltip.hover
              title="Detail" name="pembelianViewBtn">
              <i class="fa fa-info"></i>
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <span class="ml-2">{{ totalViewText }}</span>
        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>

    <b-modal id="m-detail" centered size="xl" :title="'Detail Pembelian'" no-close-on-backdrop no-close-on-esc
      hide-footer>
      <template v-if="rowSelected">
        <b-table bordered hover show-empty :busy="isLoading" :items="rowSelected.detail" :fields="detailfields"
          :perPage="perPage" responsive class="mb-3">
          <template v-slot:cell(harga)="row">
            <div class="d-flex justify-content-between">
              <span>Rp</span>
              <span>{{ $helper.rupiah(row.item.harga) }}</span>
            </div>
          </template>
          <template v-slot:cell(total_harga)="row">
            <div class="d-flex justify-content-between">
              <span>Rp</span>
              <span>{{ $helper.rupiah(row.item.total_harga) }}</span>
            </div>
          </template>
        </b-table>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import DateRangePicker from 'vue2-daterange-picker'
import { mapState } from "vuex";
import axiosClient from "../../../../config/api";
Vue.component("downloadExcel", JsonExcel);
export default {
  name: "laporan-pembelian",
  components: {
    Vue,
    DateRangePicker
  },
  data() {
    return {
      dateRange: { startDate: null, endDate: null },
      filter: {
        startDate: this.$route.query.start_date
          ? this.$helper.convertDateToDatePicker(this.$route.query.start_date)
          : "",
        endDate: this.$route.query.end_date
          ? this.$helper.convertDateToDatePicker(this.$route.query.end_date)
          : "",
        month: this.$route.query.month || "",
        cabang_kode: null
      },
      fields: [
        { key: "no_invoice", label: "No Invoice" },
        { key: "tanggal", label: "Tanggal" },
        { key: "supplier", label: "Supplier" },
        { key: "totalUnit", label: "Total Unit" },
        { key: "totalPembelian", label: "Total Pembelian" },
        { key: "status", label: "Status" },
        { key: "action", label: "Aksi" },
      ],
      detailfields: [
        { key: "nomer_barang", label: "No Barang" },
        { key: "detail_barang", label: "Nama" },
        { key: "harga", label: "Harga" },
        { key: "jumlah", label: "Jumlah" },
        { key: "total_harga", label: "Total" },
      ],
      rowSelected: null,
      cabang: [],
      supplier: [],
      pembelian: [],
      currentPage: this.$route.query.page || 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      query: "",
      role: localStorage.getItem("role")
    };
  },
  methods: {
    handleOpenDetail(data) {
      console.log(data)
      this.rowSelected = data;
      this.$bvModal.show('m-detail')
    },
    handleSelectSupplier(value) {
      this.query = {
        id_supplier: value.id,
        ...this.query
      }
    },
    handleSelectDate(value) {
      const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
      const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
      this.query = {
        startDate: startDate,
        endDate: endDate,
        ...this.query
      }
    },
    async getLaporanPembelian() {
      const cabangIds = this.cabang.map(i => i.kode);
      try {
        const response = await axiosClient({
          url: `admin/laporan/invoice`,
          params: {
            cabang_kode: cabangIds,
            ...this.query
          }
        })
        const datas = response.data.data;

        this.pembelian = datas.data.map(data => {
          return {
            id: data.id,
            no_invoice: data.no_invoice,
            tanggal: data.tanggal,
            supplier: data.supplier ? data.supplier.nama : "-",
            totalUnit: data.total_unit,
            detail: data.detail,
            totalPembelian: this.$helper.rupiah(data.total_pembelian),
            status: data.flag,
          }
        })
        this.perPage = datas.per_page;
        this.size = this.perPage;
        this.from = datas.from;
        this.limit = datas.to;
        this.updateTotalItem(datas.total);
        let msg = this.$helper.getFlash();
        this.$helper.toastSucc(this, msg);
      } catch (error) {
        console.log(error)
      }
    },

    handleEndDateChange() {
      if (this.filter.endDate < this.filter.startDate) {
        this.$bvToast.toast(
          "Tanggal Akhir tidak boleh kurang dari Tanggal Awal",
          {
            title: "Peringatan",
            variant: "warning",
            solid: true,
          }
        );

        this.filter.endDate = "";
      }
    },
    onPageChange() {
      console.log(this.currentPage)
      this.query = {
        ...this.query,
        page: this.currentPage,
      }
      this.getLaporanPembelian()
    },

    onReset() {
      this.query = ""
      this.cabang = []
      // this.getLaporanPembelian()
      this.pembelian = null
    },

    updateTotalItem(total) {
      this.perPage = this.size;
      this.rows = total;
      if (this.limit > this.rows) limit = this.rows;
      this.totalViewText =
        "Menampilkan " +
        this.from +
        " - " +
        this.limit +
        " dari " +
        this.rows +
        " entri";
    },
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
    suppliers() {
      const response = this.$store.getters.supplierList;
      const data = response.map(item => {
        return {
          id: item.id,
          nama: item.nama,
        }
      })
      return data;
    },
    cabangLists() {
      const response = this.$store.getters.cabangLists;
      const data = response.map(item => {
        return {
          id: item.id,
          name: item.name,
          kode: item.kode
        }
      })
      return data;
    },
  },
  created() {
    this.getLaporanPembelian()
    this.$store.dispatch("getSupplier")
    this.$store.dispatch("getCabang")
  },
};
</script>
